// GET
export const GET_QUOTATION_LIST = `/api/quotation`
export const GET_QUOTATION = `/api/quotation/[quotation_id]`
export const GET_LINE_QUOTATIONS_PATH = `/api/quotation/[quotation_id]/line_items`
export const GET_QUOTATION_AUDIT_HISTORY_LIST_PATH = `/api/quotation/[quotation_id]/histories`
export const GET_EXPORT_QUOTATION = `/api/quotation/export`
export const GET_NEXT_QUOTATION_NO_PATH = `/api/quotation/next_number`

// POST
export const POST_CREATE_QUOTATION_PATH = GET_QUOTATION_LIST
export const POST_MAKE_PAYMENT_PATH = `/api/quotation/[quotation_id]/payments`
export const POST_UPDATE_QUOTATION_STATUS_PATH = `/api/quotation/[quotation_id]/status`
export const POST_SEND_QUOTATION_EMAIL = `/api/quotation/send_email`
export const POST_CREATE_QUOTATION_PDF = `/api/quotation/pdf`
export const POST_CREATE_QUOTATION_AUDITS= `/api/quotation/[quotation_id]/audits`

//PATCH
export const PATCH_UPDATE_PATH = GET_QUOTATION

// DELETE
export const DELETE_QUOTATION_PATH = `/api/quotation/[quotation_id]/destroy`
