import { DATE_FORMAT } from "@/components/formFields/DateTimeField/DateTimeField.constants";
import {
  DATE_AFTER_MSG,
  DATE_CHAR_LENGTH_MSG,
  DATE_FORMAT_ERROR_MSG
} from "@/public/constants/form";
import { ListParams } from "@/types/common/baseReqRes";
import { compareAsc, isMatch, parse } from "date-fns";
import * as yup from "yup";
import { TQuotationStatus } from "./quotation.model";

export const QuotationSearchFields = {
  queryTerm: "query_term",
  store: "store",
  quotationDate: "quotation_date",
  dueDate: "due_date",
  status: "status",
} as const;

export interface QuotationListParams extends ListParams {
  [QuotationSearchFields.queryTerm]: string;
  [QuotationSearchFields.quotationDate]: string | null;
  [QuotationSearchFields.dueDate]: string | null;
  [QuotationSearchFields.store]: string | null;
  [QuotationSearchFields.status]: TQuotationStatus | null;
}

export interface QuotationSearchDto
  extends Omit<QuotationListParams, keyof ListParams> {}

export const defaultQuotationSearchFields: QuotationSearchDto = {
  query_term: "",
  store: null,
  quotation_date: null,
  due_date: null,
  status: null,
};

export const quotationSearchSchema = yup.object().shape({
  [QuotationSearchFields.queryTerm]: yup.string(),
  [QuotationSearchFields.quotationDate]: yup
    .string()
    .nullable()
    .max(20, "Invoice Date" + DATE_CHAR_LENGTH_MSG)
    .test("Date format", (value, ctx) => {
      if (!value) return true;
      return (
        isMatch(value, DATE_FORMAT) ||
        ctx.createError({ message: DATE_FORMAT_ERROR_MSG })
      );
    })
    .when(QuotationSearchFields.dueDate, (fieldValues: string[], field, startDateField) => {

      const endDate = fieldValues[0] ? parse(fieldValues[0], DATE_FORMAT, new Date()) : null
      const startDate = startDateField?.value ? parse(startDateField.value, DATE_FORMAT, new Date()) : null
      if (endDate && startDate) {
        const endDateIsAfter = compareAsc(endDate, startDate) 
  
        if (endDateIsAfter < 0) {
          return field.test("Validate Date Is After", (val, ctx) => ctx.createError({ message: `Quotation Due Date ${DATE_AFTER_MSG} Quotation Date` }))
        }
      }
  
      return field
    })
    ,
  [QuotationSearchFields.dueDate]: yup
    .string()
    .nullable()
    .max(20, "Invoice Date" + DATE_CHAR_LENGTH_MSG)
    .test("Date format", (value, ctx) => {
      if (!value) return true;
      return (
        isMatch(value, DATE_FORMAT) ||
        ctx.createError({ message: DATE_FORMAT_ERROR_MSG })
      );
    }),
  [QuotationSearchFields.store]: yup.string().nullable(),
  [QuotationSearchFields.status]: yup.string().nullable(),
});
